.item-icon {
    position: relative;
    width: 128px;
    height: 128px;
    justify-content: center;
    align-items: center;
    padding: 0.75rem;
    flex: 0 0 auto;

    img {
        border-radius: 5px;
        object-fit: cover;
        height: 100%;
    }

    .open-closed-dot {
        color: #fff;
        position: absolute;
        top: 2px;
        right: 2px;
        border-radius: 100px;
        border: 2px solid #fff;
        padding: 0.1rem 0.25rem 0.2rem;
        font-size: 12px;
        line-height: 12px;
        display: inline-flex;
        align-items: center;
        font-weight: bold;
        box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.1);

        &.red {
            background: #e74c3c;
        }

        &.green {
            background: #27ae60;
        }
    }

    // * > &:hover {
    //     .open-closed-dot {
    //         border-color: rgba(112, 161, 255, 0.1);
    //     }
    // }
}
