html,
body {
    height: 100%;
    width: 100%;
    font-family: "DM Sans", sans-serif;
}

* > * {
    box-sizing: border-box;
    font-family: "sofia-pro", sans-serif;
}

#root {
    height: 100%;
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
        "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
        "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
        monospace;
}

#google-map {
    height: 100%;
    width: 100%;
}
