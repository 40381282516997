.home-container {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    height: calc(100% - 75px);
    width: 100%;

    @media only screen and (max-width: 900px) {
        height: calc(100% - 60px);
    }
}
