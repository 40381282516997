header.header {
    position: relative;
    z-index: 9999;
    width: 100%;
    top: 0;
    left: 0;
    height: 75px;
    background: #fff;
    display: flex;
    align-items: center;
    padding: 0 2rem;
    border-bottom: 1px solid #6f6f6f;

    .logo {
        width: 40px;
    }

    h1 {
        font-size: 2rem;
        margin-left: 1.5rem;
    }

    .hamburger {
        margin-right: 1.2rem;
        display: none;
    }
}

@media only screen and (max-width: 900px) {
    header.header {
        height: 60px;
        padding: 0 1rem;

        .logo {
            width: 34px;
        }

        h1 {
            font-size: 1.4rem;
            margin-left: 1rem;
        }

        .hamburger {
            display: inline-block;
        }
    }
}
