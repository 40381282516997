.sidebar {
    width: 400px;
    background: #fafafa;
    height: 100%;
    border-right: 1px solid #6f6f6f;
}

.sidebar-wrapper {
    position: relative;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.sidebar-content {
    width: 100%;
    padding: 0.75rem;
    overflow-y: scroll;
    margin-top: 2.75rem;
}

@media only screen and (max-width: 900px) {
    .sidebar {
        position: fixed;
        width: 100%;
        height: calc(100% - 60px);
        z-index: 100;
        transition: 300ms ease-out transform;
        will-change: transform;
        -webkit-overflow-scrolling: touch;

        &.hidden {
            transform: translateX(-100%);
        }

        &.visible {
            transform: none;
        }
    }
}
