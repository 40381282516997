.row-input-container {
    position: absolute;
    z-index: 10000;
    top: 0;
    left: 0;
    right: 0;

    .row-input {
        display: flex;
        background: #3498db;
        border-bottom: 1px solid #6f6f6f;
        height: 2.75rem;

        &.vertical {
            flex-direction: column;

            .row-input-option {
                border-right: 0;
                border-bottom: 1px solid #6f6f6f;

                &:last-child {
                    border-bottom: 0;
                }
            }
        }
    }

    .row-input-option {
        flex: 1;
        position: relative;
        border-right: 1px solid #6f6f6f;

        input[type="radio"] {
            position: absolute;
            opacity: 0;
            cursor: pointer;
            height: 0;
            width: 0;
        }

        label {
            display: flex;
            align-items: center;
            justify-content: center;
            color: #fff;
            padding: 0.5rem 0.25rem;
            cursor: pointer;
            height: 100%;
            text-align: center;
            font-size: 1.25rem;
            line-height: 1;
        }

        &.selected {
            background: #2f89c5;
            box-shadow: inset 0 0 4px 4px rgba(0, 0, 0, 0.1);

            label {
                cursor: default;
            }
        }

        &:last-child {
            border-right: 0;
        }
    }
}
