.map-container {
    position: relative;
    height: 100%;
    bottom: 0;
    width: calc(100% - 400px);
}

@media only screen and (max-width: 900px) {
    .map-container {
        width: 100%;
    }
}
