.geosuggest {
    position: relative;
}

.geosuggest__suggests {
    position: absolute;
    top: 120%;
    left: 0;
    right: 0;
    max-height: 25em;
    padding: 0;
    background: #fff;
    border: 2px solid #267dc0;
    border-radius: 6px;
    overflow-x: hidden;
    overflow-y: auto;
    list-style: none;
    z-index: 5;
    transition: max-height 0.2s, border 0.2s;
}

.geosuggest__suggests--hidden {
    max-height: 0;
    overflow: hidden;
    border-width: 0 2px;
}

/**
 * A geosuggest item
 */
.geosuggest__item {
    font-size: 18px;
    font-size: 1rem;
    padding: 0.5em 1em;
    cursor: pointer;

    &:hover,
    &:focus {
        background: #f5f5f5;
    }
}

.geosuggest__item--active {
    background: #267dc0;
    color: #fff;

    &:hover,
    &:focus {
        background: #ccc;
    }
}

.geosuggest__item__matched-text {
    font-weight: bold;
}
