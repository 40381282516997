.form-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 0.25s all;
    will-change: visibility opacity;

    &.open {
        visibility: visible;
        opacity: 1;
    }

    &.closed {
        visibility: hidden;
        opacity: 0;
    }

    .background {
        background-color: rgba(0, 0, 0, 0.3);
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

    .modal {
        height: 600px;
        max-height: calc(100% - 2rem);
        width: 500px;
        max-width: calc(100% - 2rem);
        background-color: #fafbfc;
        border-radius: 8px;
        display: flex;
        flex-direction: column;
        z-index: 2;
        position: relative;

        .close-button {
            position: absolute;
            top: 1rem;
            right: 1.25rem;
            cursor: pointer;
            padding: 0.25rem 0.5rem;
            z-index: 10;

            i {
                font-size: 2.25rem;
            }
        }

        .loader-container {
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .form-success {
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            color: #27ae60;

            h2 {
                font-size: 3rem;
                margin-top: 1rem;
            }

            i {
                font-size: 8rem;
            }
        }

        .header {
            border-bottom: 1px solid lightgray;
            padding: 1.5rem;

            h2 {
                padding-right: 2rem;
                margin-bottom: 1rem;
            }

            .notification {
                font-size: 18px;
                margin-top: 0.5rem;

                &.new {
                    color: #27ae60;
                }

                &.repeat {
                    color: #e74c3c;
                }

                i {
                    margin-right: 0.5rem;
                }
            }
        }

        .content {
            padding: 1.5rem;
            position: relative;
            flex: 1;
        }

        input {
            width: 100%;
            box-shadow: 0 0 0 transparent;
            padding: 0.5em 1em;
            transition: border 0.2s, box-shadow 0.2s;
            font-size: 18px;
            border-radius: 6px;
            border: 2px solid #267dc0;
            margin-top: 1rem;
            margin-bottom: 0.25rem;

            &:focus {
                box-shadow: 0 0 2px #3d464d;
                outline: none;
            }

            &:first-child {
                margin-top: 0;
            }
        }

        .submit-button {
            width: calc(100% - 3rem);
            border-radius: 6px;
            position: absolute;
            bottom: 1.5rem;
            left: 1.5rem;
            height: 2.75rem;

            &:disabled {
                background-color: darkgrey;
            }
        }
    }
}

.open-form-button {
    .button-text {
        padding-bottom: 2px;
    }
}

@media only screen and (max-width: 650px) {
    .open-form-button {
        .button-text {
            display: none;
        }

        i {
            margin: 0;
        }
    }
}
