.list-item {
    margin-bottom: 0.75rem;
    border-radius: 8px;
    background: #fff;
    box-shadow: 0 0px 0px 0 rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 1px solid transparent;
    transition: 0.2s ease all;
    cursor: pointer;
    border: 1px solid #dedede;

    &:last-child {
        margin: 0;
    }

    &:hover {
        border: 1px solid #70a1ff;
        background: rgba(112, 161, 255, 0.1);
        box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.1);
    }

    .item-preview {
        display: flex;
        width: 100%;
    }

    .item-content {
        height: 100%;
    }

    .item-content {
        padding: 0.75rem 0.75rem 0.75rem 1rem;
        flex: 1;
    }

    .list-item-header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-start;
        margin-bottom: 0.25rem;
    }

    .rating {
        font-size: 16px;
        font-weight: bold;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-left: 1rem;
        margin-right: 0.5rem;
        padding-top: 0.1rem;
    }

    .review-open {
        margin-top: 1rem;
    }

    .star {
        height: 14px;
        width: 14px;
        background-size: 14px 14px;
        background-image: url(//maps.gstatic.com/consumer/images/icons/2x/ic_star_rate_14.png);
        display: block;
        margin-right: 5px;
    }

    .store-name {
        color: #222;
    }

    .address {
        color: #777;
        font-size: 15px;
    }

    .item-details {
        display: flex;
        flex-direction: row;
        width: 100%;
        padding: 0.5rem;
        padding-top: 1rem;
    }

    .item-details[hidden] {
        display: none;
    }

    .store-hours {
        flex: 0 0 75%;
        display: flex;
        flex-direction: column;
        color: #999;
    }
}
