@import-normalize;
@import "@fortawesome/fontawesome-free/scss/fontawesome.scss";
@import "@fortawesome/fontawesome-free/scss/solid.scss";
@import "@fortawesome/fontawesome-free/scss/regular.scss";

* {
    box-sizing: border-box;
}

.App {
    height: 100%;
}

h1,
h2,
h3,
h4,
h5,
ul,
li,
ol,
p,
span,
section,
aside {
    margin: 0;
    padding: 0;
}

* > img {
    display: block;
    width: 100%;
}

.locate-button {
    background-color: #fff;
    border-radius: 2px;
    box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 4px -1px;
    cursor: pointer;
    overflow: hidden;
    margin: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    width: 40px;

    &:hover {
        .locate-button-icon.light {
            display: none;
        }
    }

    .locate-button-icon {
        color: rgb(25, 25, 25);
        font-size: 16px;
        height: 24px;
        width: 24px;
        background-size: 24px 24px;
        background-image: url(/images/gps-filled-icon.svg);
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        &.light {
            z-index: 1;
            background-image: url(/images/gps-filled-icon-light.svg);
        }
    }
}

.gm-style-iw-c {
    padding: 0 !important;
    border-radius: 4px !important;
    width: 256px;
    max-width: 256px;

    .ratings-bagde {
        color: #fff;
        position: fixed;
        top: 4px;
        left: 5px;
        border-radius: 100px;
        border: 2px solid #fff;
        padding: 3px 5px 5px 5px;
        font-size: 14px;
        line-height: 6px;
        display: inline-flex;
        align-items: center;
        font-weight: bold;
        box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.1);
        background: #f39c12;
        height: 22px;
        i {
            padding-left: 0.25rem;
            padding-top: 1px;
            font-size: 11px;
        }
    }

    .open-closed-dot {
        color: #fff;
        position: fixed;
        top: 4px;
        right: 5px;
        border-radius: 100px;
        border: 2px solid #fff;
        padding: 0.1rem 0.25rem 0.2rem;
        font-size: 12px;
        line-height: 12px;
        display: inline-flex;
        align-items: center;
        font-weight: bold;
        box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.1);
        height: 22px;

        &.red {
            background: #e74c3c;
        }

        &.green {
            background: #27ae60;
        }
    }

    .gm-style-iw-d {
        max-width: 100% !important;
        overflow-x: hidden !important;
        &::-webkit-scrollbar {
            width: 0 !important;
        }
        overflow: -moz-scrollbars-none;
        -ms-overflow-style: none;

        & > div {
            width: 100% !important;
        }
    }

    .gm-ui-hover-effect {
        display: none !important;
        width: 0 !important;
        opacity: 1 !important;

        img {
            background: white;
            border-radius: 2px;
        }
    }

    img {
        width: 100%;
        max-width: 256px;
        height: 172px;
        object-fit: cover;
        z-index: 1000;
    }

    .info-window-content {
        box-sizing: border-box;
        margin: 1rem;
        width: calc(100% - 2rem);
        font-size: 15px;

        .info-window-title {
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
            margin-bottom: 0.5rem;

            .rating {
                font-size: 16px;
                font-weight: bold;
                display: flex;
                align-items: center;
                margin-left: 1rem;
                line-height: 2.4rem;

                .star {
                    height: 16px;
                    width: 16px;
                    background-size: 16px 16px;
                    background-image: url(//maps.gstatic.com/consumer/images/icons/2x/ic_star_rate_14.png);
                    display: block;
                    margin-left: 4px;
                }
            }
        }

        .distance {
            margin-left: auto;
            color: gray;
            display: inline-block;
        }

        .iw-button-container {
            margin-top: 1rem;
            a {
                background: #3498db;
                color: white;
                text-decoration: none;
                padding: 0.5rem 0.75rem;
                font-size: 1.25rem;
                line-height: 1.25rem;
                border-radius: 3px;
                display: inline-flex;
                align-items: center;
                box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.1);
                width: 100%;
                height: 2.5rem;
                justify-content: center;

                i {
                    margin-right: 0.75rem;
                }
            }
        }
    }
}

@media only screen and (max-width: 650px) {
    .gm-style-iw-c {
        img {
            height: 120px;
        }
    }
}

.primary-button {
    background: #3498db;
    color: white;
    text-decoration: none;
    padding: 0.5rem 0.75rem;
    font-size: 1.25rem;
    line-height: 1.25rem;
    border-radius: 3px;
    border: none;
    display: inline-flex;
    align-items: center;
    box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.1);
    height: 2.5rem;
    justify-content: center;
    margin-left: auto;
    cursor: pointer;
    transition: 0.1s all;

    i {
        margin-right: 0.75rem;
    }

    &:hover {
        background-color: #2f89c5;
    }
}
